import {
  useQuery,
  useQueryClient,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from '@tanstack/react-query';
import { api } from './api';
import {
  DeckRequest,
  DeckResponse,
  BulkCollectionUploadRequest,
  BulkCollectionUploadResponse,
} from 'flashcards-api-schema';

export const useFetchDecks = (): UseQueryResult<DeckResponse[], Error> => {
  return useQuery<DeckResponse[], Error>({
    queryKey: ['decks'],
    queryFn: async () => {
      const response = await api.get('/decks');
      return response.data;
    },
  });
};

export const useFetchDeck = (id: string): UseQueryResult<DeckResponse, Error> => {
  return useQuery<DeckResponse, Error>({
    queryKey: ['decks', id],
    queryFn: async () => {
      const response = await api.get(`/decks/${id}`);
      return response.data;
    },
    enabled: !!id,
  });
};

export const useCreateDeck = (): UseMutationResult<
  DeckRequest,
  Error,
  Partial<DeckRequest>,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation<DeckRequest, Error, Partial<DeckRequest>>({
    mutationFn: async (newDeck) => {
      const response = await api.post('/decks', newDeck);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['decks'] });
    },
  });
};

export const useUpdateDeck = (): UseMutationResult<
  DeckRequest,
  Error,
  { id: string; data: Partial<DeckRequest> },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation<DeckRequest, Error, { id: string; data: Partial<DeckRequest> }>({
    mutationFn: async ({ id, data }) => {
      const response = await api.patch(`/decks/${id}`, data);
      return response.data;
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: ['decks', id] });
      queryClient.invalidateQueries({ queryKey: ['decks'] });
    },
  });
};

export const useDeleteDeck = (): UseMutationResult<void, Error, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: async (id) => {
      await api.delete(`/decks/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['decks'] });
    },
  });
};

export const useBulkUpload = (): UseMutationResult<
  BulkCollectionUploadResponse,
  Error,
  BulkCollectionUploadRequest,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation<BulkCollectionUploadResponse, Error, BulkCollectionUploadRequest>({
    mutationFn: async (data) => {
      const response = await api.post('/decks/bulk', data);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['decks'] });
    },
  });
};
