import React from 'react';
import type { BulkCollectionUploadResponse } from 'flashcards-api-schema';

interface UploadSuccessProps {
  data: BulkCollectionUploadResponse;
}

export const UploadSuccess: React.FC<UploadSuccessProps> = ({ data }) => {
  const message = `Successfully created ${data.decksCreated} decks and ${data.cardsCreated} cards.`;
  const skippedMessage = `Skipped ${data.skippedDecks.length} existing decks`;

  return (
    <div className="mt-4 text-center text-green-600">
      <div>{message}</div>

      {data.skippedDecks.length > 0 && <div className="mt-2 text-yellow-600">{skippedMessage}</div>}
    </div>
  );
};
