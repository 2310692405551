import { useCallback } from 'react';
import { FlashcardResponse, DeckResponse } from 'flashcards-api-schema';

export type SortKey = 'deck' | 'question' | 'answer';
export type SortOrder = 'asc' | 'desc';

export function useCardSorting(decks: DeckResponse[] | undefined) {
  const getSortValue = useCallback(
    (card: FlashcardResponse, key: SortKey) => {
      switch (key) {
        case 'deck':
          return decks?.find((d) => d.id === card.deckId)?.name || card.deckId;
        case 'question':
          return card.front;
        case 'answer':
          return card.back;
        default:
          return '';
      }
    },
    [decks]
  );

  const compareValues = useCallback(
    (a: FlashcardResponse, b: FlashcardResponse, sortKey: SortKey) => {
      const aField = getSortValue(a, sortKey);
      const bField = getSortValue(b, sortKey);
      return aField < bField ? -1 : aField > bField ? 1 : 0;
    },
    [getSortValue]
  );

  const sortCards = useCallback(
    (cards: FlashcardResponse[], sortKey: SortKey, sortOrder: SortOrder) => {
      const sorted = [...cards].sort((a, b) => compareValues(a, b, sortKey));
      return sortOrder === 'desc' ? sorted.reverse() : sorted;
    },
    [compareValues]
  );

  return { sortCards };
}
