"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkCollectionUploadResponseSchema = exports.deckResponseSchema = exports.BulkCollectionUploadSchema = exports.deckRequestSchema = void 0;
const zod_1 = require("zod");
exports.deckRequestSchema = zod_1.z.object({
    name: zod_1.z.string().min(1).max(50),
    parentDeckId: zod_1.z.string().uuid().optional(),
    maximumInterval: zod_1.z.number().optional(),
});
exports.BulkCollectionUploadSchema = zod_1.z.object({
    decks: zod_1.z.array(zod_1.z.object({
        name: zod_1.z.string().min(1).max(50),
        parentDeckName: zod_1.z.string().optional(),
    })),
    cards: zod_1.z.array(zod_1.z.object({
        front: zod_1.z.string().min(1).max(1500),
        back: zod_1.z.string(),
        deckName: zod_1.z.string(),
    })),
});
// Response types
exports.deckResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string().optional(),
    createdAt: zod_1.z.date(),
    updatedAt: zod_1.z.date(),
    flashcardIds: zod_1.z.array(zod_1.z.string()),
    subdeckIds: zod_1.z.array(zod_1.z.string()),
    parentDeckId: zod_1.z.string().optional(),
    type: zod_1.z.literal("regular").default("regular"),
});
exports.BulkCollectionUploadResponseSchema = zod_1.z.object({
    decksCreated: zod_1.z.number(),
    cardsCreated: zod_1.z.number(),
    skippedDecks: zod_1.z.array(zod_1.z.string()),
});
