import { useSelectedDecks } from './use-selected-decks';
import { FlashcardResponse, DeckResponse } from 'flashcards-api-schema';
import { useFetchFlashcards } from '../query';
import { useMemo, useState, useCallback } from 'react';

const sortByRanking = (a: FlashcardResponse, b: FlashcardResponse) =>
  (b.rankingScore ?? 0) - (a.rankingScore ?? 0);

const filterByCustomDeck = (flashcard: FlashcardResponse, deckId: string) =>
  flashcard.deckId === deckId;

const getSubDeckIds = (deck: DeckResponse, decks: DeckResponse[]): string[] => {
  const visited = new Set<string>();
  const stack: string[] = [deck.id];
  while (stack.length > 0) {
    const currentDeckId = stack.pop();
    const currentDeck = decks.find((d) => d.id === currentDeckId);
    if (!currentDeckId) continue;
    if (visited.has(currentDeckId)) continue;
    visited.add(currentDeckId);
    const subDeckIds = currentDeck?.subdeckIds ?? [];
    stack.push(...subDeckIds);
  }
  return Array.from(visited);
};

const filterByRegularDecks = (flashcard: FlashcardResponse, allValidDeckIds: string[]) => {
  if (!allValidDeckIds) return false;

  return allValidDeckIds.includes(flashcard.deckId);
};

export const useCurrentFlashcards = () => {
  const {
    selectedRegularDecks,
    selectedCustomDeck,
    isLoading: isDecksLoading,
    error: decksError,
  } = useSelectedDecks();
  const {
    data: flashcards,
    isLoading: isFlashcardsLoading,
    error: flashcardsError,
  } = useFetchFlashcards();

  // Track the currently viewed card to maintain its position
  const [lockedCardId, setLockedCardId] = useState<string | null>(null);

  const lockCard = useCallback((cardId: string) => {
    setLockedCardId(cardId);
  }, []);

  const unlockCard = useCallback(() => {
    setLockedCardId(null);
  }, []);

  const allValidDeckIds = useMemo(() => {
    if (!selectedRegularDecks) return [];
    return selectedRegularDecks.flatMap((deck) => [
      deck.id,
      ...getSubDeckIds(deck, selectedRegularDecks),
    ]);
  }, [selectedRegularDecks]);

  const currentFlashcards = useMemo(() => {
    if (!flashcards) return [];

    const filteredCards = selectedCustomDeck
      ? flashcards.filter((card) => filterByCustomDeck(card, selectedCustomDeck.id))
      : flashcards.filter((card) => filterByRegularDecks(card, allValidDeckIds));
    console.log('selectedRegularDecks', selectedRegularDecks);
    console.log('allValidDeckIds', allValidDeckIds);
    console.log('filteredCards', filteredCards);

    // If we have a locked card, ensure it maintains its position
    if (lockedCardId) {
      const lockedCard = filteredCards.find((card) => card.id === lockedCardId);
      if (lockedCard) {
        // Remove the locked card from the array
        const otherCards = filteredCards.filter((card) => card.id !== lockedCardId);
        // Sort the remaining cards
        const sortedOtherCards = otherCards.sort(sortByRanking);
        // Find the original index of the locked card
        const originalIndex = filteredCards.findIndex((card) => card.id === lockedCardId);
        // Insert the locked card back at its original position
        sortedOtherCards.splice(originalIndex, 0, lockedCard);
        return sortedOtherCards;
      }
    }

    return filteredCards.sort(sortByRanking);
  }, [flashcards, selectedCustomDeck, selectedRegularDecks, allValidDeckIds, lockedCardId]);

  return {
    currentFlashcards,
    isLoading: isDecksLoading || isFlashcardsLoading,
    error: decksError || flashcardsError,
    lockCard,
    unlockCard,
  };
};
