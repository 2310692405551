import React, { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useUpdateFlashcard } from '@/query';
import { FlashcardResponse } from 'flashcards-api-schema';
import { CircularProgress } from './circular-progress';
import { useCreateReview } from '@/query/review';
import { Pin, PinOff } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { useCurrentFlashcards } from '@/hooks/use-current-flashcards';

interface FlashcardProps {
  currentCard: FlashcardResponse;
  isFlipped: boolean;
  handleFlipCard: () => void;
  onNextCard: () => void;
}

interface CardHeaderProps {
  isFlipped: boolean;
}

const CardHeader: React.FC<CardHeaderProps> = ({ isFlipped }) => {
  return (
    <h2 className="absolute left-2 top-2 text-sm text-gray-500">{isFlipped ? 'Back' : 'Front'}</h2>
  );
};

interface CardControlsProps {
  currentCard: FlashcardResponse;
}

const CardControls: React.FC<CardControlsProps> = ({ currentCard }) => {
  const updateFlashcard = useUpdateFlashcard();
  const { toast } = useToast();
  const { lockCard } = useCurrentFlashcards();

  const handlePriorityChange = (value: string): void => {
    lockCard(currentCard.id);
    updateFlashcard.mutate({
      id: currentCard.id,
      data: { priority: parseInt(value) },
    });
    toast({
      title: 'Priority updated',
      description: `Card priority set to P${value}`,
      variant: 'default',
    });
  };

  const handlePinClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    lockCard(currentCard.id);
    updateFlashcard.mutate({
      id: currentCard.id,
      data: { isPinned: !currentCard.isPinned },
    });
    toast({
      title: currentCard.isPinned ? 'Card unpinned' : 'Card pinned',
      description: currentCard.isPinned
        ? 'Card removed from pinned cards'
        : 'Card added to pinned cards',
      variant: 'default',
    });
  };

  const priorityOptions = [
    { value: '0', label: 'P0' },
    { value: '1', label: 'P1' },
    { value: '2', label: 'P2' },
    { value: '3', label: 'P3' },
  ];

  return (
    <div
      className="flex flex-col items-center gap-4"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <Button size="icon" variant="outline" onClick={handlePinClick}>
        {currentCard.isPinned ? <PinOff size={16} /> : <Pin size={16} />}
      </Button>

      <Select value={currentCard.priority?.toString() ?? '3'} onValueChange={handlePriorityChange}>
        <SelectTrigger className="w-[80px]">
          <SelectValue placeholder="Priority" />
        </SelectTrigger>

        <SelectContent>
          {priorityOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

interface ReviewButtonsProps {
  currentCard: FlashcardResponse;
  onNextCard: () => void;
}

const ReviewButtons: React.FC<ReviewButtonsProps> = ({ currentCard, onNextCard }) => {
  const createReview = useCreateReview();
  const { unlockCard } = useCurrentFlashcards();

  const handleReview = (reviewScore: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    unlockCard();
    createReview.mutate({
      flashcardId: currentCard.id,
      reviewScore,
    });
    onNextCard();
  };

  return (
    <div className="flex w-full justify-center gap-20">
      <Button
        size="sm"
        className="border border-red-500 bg-transparent text-red-500 hover:bg-red-500 hover:text-white"
        onClick={handleReview(0)}
      >
        Again
      </Button>

      <Button
        size="sm"
        className="border border-orange-500 bg-transparent text-orange-500 hover:bg-orange-500 hover:text-white"
        onClick={handleReview(3)}
      >
        Hard
      </Button>

      <Button
        size="sm"
        className="border border-yellow-500 bg-transparent text-yellow-500 hover:bg-yellow-500 hover:text-white"
        onClick={handleReview(7)}
      >
        Good
      </Button>

      <Button
        size="sm"
        className="border border-green-500 bg-transparent text-green-500 hover:bg-green-500 hover:text-white"
        onClick={handleReview(10)}
      >
        Easy
      </Button>
    </div>
  );
};

export const FlashcardView: React.FC<FlashcardProps> = ({
  currentCard,
  isFlipped,
  handleFlipCard,
  onNextCard,
}) => {
  const { lockCard } = useCurrentFlashcards();

  useEffect(() => {
    lockCard(currentCard.id);
  }, [currentCard.id, lockCard]);

  return (
    <div className="relative flex aspect-[2/1] w-full max-w-[806px] cursor-pointer flex-col items-center justify-between rounded-lg border bg-slate-50 p-4 dark:bg-slate-900">
      <div className="flex h-full w-full gap-4">
        <div
          className="flex flex-1 flex-col items-center justify-center gap-20"
          onClick={handleFlipCard}
        >
          <CardHeader isFlipped={isFlipped} />

          <p className="text-xl md:text-2xl">{isFlipped ? currentCard.back : currentCard.front}</p>

          <ReviewButtons currentCard={currentCard} onNextCard={onNextCard} />
        </div>

        <Separator orientation="vertical" className="h-auto" />

        <div className="flex flex-col items-center justify-between">
          <CardControls currentCard={currentCard} />

          <CircularProgress size="md" value={currentCard.masteryScore ?? 0} variant="success" />
        </div>
      </div>
    </div>
  );
};
