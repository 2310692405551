import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useToast } from '@/hooks/use-toast';
import { useBulkUpload } from '@/query/deck';
import { readAndParseFile, normalizeData, processCardsForUpload } from './anki-processor';
import { UploadSuccess } from './UploadSuccess';

export const CreateView: React.FC = () => {
  const { toast } = useToast();
  const bulkUploadMutation = useBulkUpload();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const file = acceptedFiles[0];
        if (!file) return;

        const cards = await readAndParseFile(file);
        const normalizedCards = normalizeData(cards);
        const uploadData = processCardsForUpload(normalizedCards);

        bulkUploadMutation.mutate(uploadData);
      } catch (error) {
        toast({
          title: 'Processing Failed',
          description: error instanceof Error ? error.message : 'Failed to process file',
          variant: 'destructive',
        });
      }
    },
    [toast, bulkUploadMutation]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt'],
    },
    maxFiles: 1,
  });

  return (
    <div className="container mx-auto p-4">
      <h1 className="mb-4 text-2xl font-bold">Create Cards from Anki Export</h1>

      <Card className="p-6">
        <div
          {...getRootProps()}
          className={`cursor-pointer rounded-lg border-2 border-dashed p-8 text-center transition-colors ${isDragActive ? 'border-primary bg-primary/10' : 'border-muted-foreground/25'}`}
        >
          <input {...getInputProps()} />

          {isDragActive ? (
            <p>Drop the file here</p>
          ) : (
            <div className="space-y-4">
              <p>Drag and drop an Anki export file here, or click to select</p>

              <Button variant="outline">Select File</Button>
            </div>
          )}
        </div>

        {bulkUploadMutation.isPending && <div className="mt-4 text-center">Processing file...</div>}

        {bulkUploadMutation.isSuccess && <UploadSuccess data={bulkUploadMutation.data} />}

        {bulkUploadMutation.isError && (
          <div className="mt-4 text-center text-red-600">
            {bulkUploadMutation.error instanceof Error
              ? bulkUploadMutation.error.message
              : 'An error occurred'}
          </div>
        )}
      </Card>
    </div>
  );
};
