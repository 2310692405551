import { useFetchDecks } from '@/query';

import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { DeckResponse } from 'flashcards-api-schema';

interface MaterialDeckTreeProps {
  deck: DeckResponse;
}

export default function MaterialDeckTree({ deck }: MaterialDeckTreeProps) {
  const { data: decks, isLoading: isLoadingDecks, error: errorDecks } = useFetchDecks();
  const childrenDecks = decks?.filter((d) => d.parentDeckId === deck.id);

  if (isLoadingDecks) {
    return <div>Loading...</div>;
  }

  if (errorDecks) {
    return <div>Error: {errorDecks.message}</div>;
  }

  return (
    <TreeItem itemId={deck.id} label={deck.name}>
      {childrenDecks?.map((d) => <MaterialDeckTree key={d.id} deck={d} />)}
    </TreeItem>
  );
}
