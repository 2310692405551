interface AnkiCard {
  question: string;
  answer: string;
  deck: string;
}

export const readAndParseFile = (file: File): Promise<AnkiCard[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const content = event.target?.result as string;
        const cards = parseAnkiContent(content);
        resolve(cards);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsText(file);
  });
};

const parseAnkiContent = (content: string): AnkiCard[] => {
  const lines = content.split('\n');
  const cards: AnkiCard[] = [];

  // Skip the first two lines (separator and html config)
  for (let i = 2; i < lines.length; i++) {
    const line = lines[i].trim();
    if (!line) continue;

    const card = parseAnkiLine(line);
    if (card) {
      cards.push(card);
    }
  }

  return cards;
};

const parseAnkiLine = (line: string): AnkiCard | null => {
  const parts = line.split('\t');
  const firstPart = parts[0];
  const deckMatch = firstPart.match(/Deck:\s*([^\t]+)/);

  if (!deckMatch) return null;

  const question = firstPart.split('Deck:')[0].trim();
  // Clean up the deck name by removing any quotes at the start or end
  const deck = deckMatch[1].trim().replace(/^["']|["']$/g, '');
  const answer = parts[1] ? parts[1].trim() : '';

  if (!question || !deck) return null;

  return {
    question,
    answer,
    deck,
  };
};

const normalizeString = (str: string): string => {
  // Clean up any quotes in addition to whitespace normalization
  return str
    .replace(/\s+/g, ' ')
    .replace(/^["']|["']$/g, '')
    .trim();
};

export const normalizeData = (cards: AnkiCard[]): AnkiCard[] => {
  return cards.map((card) => ({
    question: normalizeString(card.question),
    answer: normalizeString(card.answer),
    deck: normalizeString(card.deck),
  }));
};

export const processCardsForUpload = (cards: AnkiCard[]) => {
  const deckSet = new Set<string>();
  const deckHierarchy = new Map<string, string>();

  // Collect all decks and their relationships
  cards.forEach((card) => {
    const deckParts = card.deck.split('::');
    let currentPath = '';

    deckParts.forEach((part, index) => {
      // Clean up each part of the deck hierarchy
      const cleanPart = part.replace(/^["']|["']$/g, '').trim();
      const fullPath = index === 0 ? cleanPart : `${currentPath}::${cleanPart}`;
      deckSet.add(fullPath);

      if (index > 0) {
        deckHierarchy.set(fullPath, currentPath);
      }

      currentPath = fullPath;
    });
  });

  return {
    decks: Array.from(deckSet).map((deckName) => ({
      name: deckName,
      parentDeckName: deckHierarchy.get(deckName),
    })),
    cards: cards.map((card) => ({
      front: card.question,
      back: card.answer,
      deckName: card.deck,
    })),
  };
};
